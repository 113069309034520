import "jquery";
import "jquery-validation-unobtrusive";
import "bootstrap";
import Choices from "choices.js";

window.addEventListener("load", () => {
	document.querySelectorAll(".choices-single").forEach(el => {
		new Choices(el,
			{
				searchPlaceholderValue: "Search",
				itemSelectText: "",
				noResultsText: "No results",
				searchResultLimit: 15,
				searchFields: ["label"],
			});
	});

	document.querySelectorAll("[data-clipboard]").forEach(el => {
		const a = document.createElement("a");
		a.setAttribute("class", "clipboard");
		a.setAttribute("href", "#");
		const i = document.createElement("i");
		i.setAttribute("class", "far fa-clone me-2 flex-shrink-0");
		a.appendChild(i);
		el.after(a);

		el.addEventListener("click", async () => {
			await navigator.clipboard.writeText(el.textContent);
		});
		a.addEventListener("click", async () => {
			await navigator.clipboard.writeText(el.textContent);
		});
	});

	document.querySelectorAll("[data-ak-showhide]").forEach(el => {
		const sel = el.getAttribute("data-ak-showhide");

		if (el instanceof HTMLInputElement && el.type == "checkbox") {
			if (el.checked) {
				document.querySelectorAll(sel).forEach(target => {
					target.classList.remove("d-none");
				});
			} else {
				document.querySelectorAll(sel).forEach(target => {
					target.classList.add("d-none");
				});
			}

			el.addEventListener("change", () => {
				if (el.checked) {
					document.querySelectorAll(sel).forEach(target => {
						target.classList.remove("d-none");
					});
				} else {
					document.querySelectorAll(sel).forEach(target => {
						target.classList.add("d-none");
					});
				}
			});

		} else {
			// simple toggle
			el.addEventListener("change", () => {
				document.querySelectorAll(sel).forEach(target => {
					target.classList.toggle("d-none");
				});
			});
		}
	})
})


