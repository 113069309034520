import "jquery";
import "jquery-validation-unobtrusive";
import "bootstrap";
import Choices from "choices.js";

window.addEventListener("load", () => {
	document.querySelectorAll(".choices-single").forEach(el => {
		new Choices(el,
			{
				searchPlaceholderValue: "Search",
				itemSelectText: "",
				noResultsText: "No results",
				searchResultLimit: 15,
				searchFields: ["label"],
			});
	});

	document.querySelectorAll("[data-clipboard]").forEach(el => {
		const a = document.createElement("a");
		a.setAttribute("class", "clipboard");
		a.setAttribute("href", "#");
		const i = document.createElement("i");
		i.setAttribute("class", "far fa-clone me-2 flex-shrink-0");
		a.appendChild(i);
		el.after(a);

		el.addEventListener("click", async () => {
			await navigator.clipboard.writeText(el.textContent);
		});
		a.addEventListener("click", async () => {
			await navigator.clipboard.writeText(el.textContent);
		});
	});

	document.querySelectorAll("[data-ak-showhide]").forEach(el => {
		const sel = el.getAttribute("data-ak-showhide");

		if (el instanceof HTMLInputElement && el.type == "checkbox") {
			if (el.checked) {
				document.querySelectorAll(sel).forEach(target => {
					target.classList.remove("d-none");
				});
			} else {
				document.querySelectorAll(sel).forEach(target => {
					target.classList.add("d-none");
				});
			}

			el.addEventListener("change", () => {
				if (el.checked) {
					document.querySelectorAll(sel).forEach(target => {
						target.classList.remove("d-none");
					});
				} else {
					document.querySelectorAll(sel).forEach(target => {
						target.classList.add("d-none");
					});
				}
			});

		} else {
			// simple toggle
			el.addEventListener("change", () => {
				document.querySelectorAll(sel).forEach(target => {
					target.classList.toggle("d-none");
				});
			});
		}
	});

	document.querySelectorAll("[data-ak-formtoggle]").forEach(target => {
		console.log(target);
		let invert = false;
		let sel = target.getAttribute("data-ak-formtoggle");
		if (sel.indexOf('!') === 0) {
			sel = sel.substring(1);
			invert = true;
		}

		let unrequiredInputs = [];

		const apply = (en: boolean) => {
			if (invert) en = !en;
			if (en) {
				target.classList.remove("d-none");
				for (const input of unrequiredInputs) {
					input.required = true;
				}
				unrequiredInputs = [];
			} else {
				target.classList.add("d-none");
				target.querySelectorAll("input").forEach(input => {
					if (input.required) {
						input.required = false;
						unrequiredInputs.push(input);
					}
				});
			}
		};

		const source = document.querySelector(sel);
		console.log(source);
		if (source instanceof HTMLInputElement && source.type == "checkbox") {
			apply(source.checked);
			source.addEventListener("change", () => {
				apply(source.checked);
			});
		}
	});
})


